.products{
	.products-menu{
		.mobile-menu-title{
			display: none;
			margin-bottom: 15px;
			cursor: pointer;
			@media (max-width:$md-width){
				display: block;
			}
			h3{
				font-family: $poppinsregular;
				@include font-size(15,24);
				color:$white;
				padding: 17px 20px;
				border:1px solid $purple;
				background: $purple;
				position: relative;
				padding-right: 45px;
				&:before{
					content:"\e901";
					font-family: 'icomoon';
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translate(0,-50%) rotate(90deg);
					color:$white;
					font-size: 14px;
				}
			}
		}
		.panel-group{
			margin:0;
			.panel-heading{
				background: none;
				border:none;
				border-radius: 0;
				padding: 0;
			}
		}
		.main-panel-group{
			display: block;
			@media (max-width:$md-width){
				display: none;
				margin-bottom: 15px;
				&.active{
					display: block;
				}
			}
			> .panel-default{
				border-radius: 0;
				box-shadow: 0 0 0 0;
                border:1px solid #d9d9d9;
                background:$white;
				+ .panel{
					margin-top: 9px;
				}
				> .panel-heading{
					a{
						display: block;
						font-family: $poppinsregular;
						@include font-size(15,24);
						color:$grey;
						background: $white;
						padding: 16px 35px 16px 20px;
						position: relative;
						text-transform: uppercase;
						&:before{
                            content:"";
							position: absolute;
							right: 20px;
							background:#cccccc;
							top: 50%;
							transform: translate(0,-50%);
                            width: 15px;
                            height:1px;
						}
                        &:after{
                            content:"";
							position: absolute;
							right: 27px;
							background:#cccccc;
							top: 50%;
                            width: 1px;
                            height:15px;
                            margin-top: -7.5px;
                            @include transition;
                            opacity: 1;
						}
						&[aria-expanded=true]{
							&:after{
								opacity:0;
							}
						}
					}
				}
				.panel-body{
					padding: 0;
                    border-top:1px solid #d9d9d9;
					> .panel-group{
						.panel-default{
							border:none;
							margin:0 !important;
							&:not(:last-child){
								border-bottom: 1px solid #dddddd;
							}
							.panel-heading{
								a{
									display: block;
									font-family: $poppinslight;
									@include font-size(14,20);
									color:$grey;
									padding: 13.5px 15px 13.5px 43px;
									position: relative;
									@include transition;
									&:before{
										content:"\e901";
										font-family: 'icomoon';
										font-size: 6px;
										color:#dddddd;
										position: absolute;
										left: 26px;
										top: 50%;
										transform: translate(0,-50%);
										@include transition;
									}
									&:after{
										content:"";
										position: absolute;
										left: 0;
										top: 0;
										width: 2px;
										height: 0;
										background: $purple;
										@include transition;
									}
									&:hover,&.active{
										background: #faf6fc;
										&:before{
											color:$purple;
										}
										&:after{
											height: 100%;
										}
									}
									i{
										position: absolute;
										right: 20px;
										color:#dddddd;
                                        width: 15px;
                                        height:15px;
                                        &:before{
                                            content:"";
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            width:15px;
                                            height:1px;
                                            background:#ccc;
                                            transform: translate(0,-50%);
                                            @include transition;
                                        }
                                        &:after{
                                            content:"";
                                            position: absolute;
                                            left: 50%;
                                            margin-left:-1px;
                                            top: 0;
                                            width: 1px;
                                            height: 15px;
                                            background: #ccc;
                                            @include transition;
                                        }
									}
									&[aria-expanded=true]{
										i{
											&:after{
                                                opacity:0;
											}
										}
									}
								}
							}
						}
					}
					ul{
						li{
							&:not(:last-child){
								border-bottom: 1px solid #dddddd;
							}
							a{
								font-family: $poppinslight;
								@include font-size(14,20);
								color:$grey;
								padding: 10px 10px 10px 52px;
								display: block;
								position: relative;
								@include transition;
								&:before{
									content:"\e901";
									font-family: 'icomoon';
									font-size: 9px;
									color:#dddddd;
									position: absolute;
									left: 35px;
									@include transition;
								}
								&:hover,&.active{
									color:$white;
									background: $purple;
									&:before{
										color:$white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.product-detail{
		.title{
			background: $white;
			border:1px solid #d9d9d9;
			padding: 15.5px 27px;
			display: table;
			width: 100%;
			@media (max-width:$md-width){
				padding: 15.5px 15px;
			}
			h3{
				font-family: $poppinsbold;
				@include font-size(17,24);
				color:$grey;
				display: inline-block;
				float: left;
				text-transform: uppercase;
			}
			span{
				display: inline-block;
				float: left;
				font-family: $poppinslight;
				@include font-size(17,24);
				color:$grey;
				position: relative;
				padding-left: 14px;
				&:before{
					content:"/";
					position: absolute;
					left: 5px;
				}
			}
		}
		figure{
			margin-top:9px;
			border:1px solid #d9d9d9;
			background: $white;
			height: 292px;
			display:flex;
			align-items: center;
			justify-content: center;
			img{
				max-width: 100%;
				max-height: 100%;
			}
		}
		section{
			background: $white;
			border:1px solid #d9d9d9;
			margin-top: 9px;
			h3{
				font-family: $poppinsbold;
				@include font-size(17,24);
				color:$grey;
				padding: 14px 27px;
				border-bottom: 1px solid #d9d9d9;
				@media (max-width:$md-width){
					padding: 14px 15px;
				}
			}
			article{
				padding: 41px 27px;
				font-family: $poppinsregular;
				@include font-size(14,22);
				color:$grey;
				@media (max-width:$md-width){
					padding: 20px;
				}
				img{
					max-width: 100%;
					height: auto !important;
				}
			}
			ul{
				li{
					&:not(:last-child){
						border-bottom: 1px solid #d9d9d9;
					}
					a{
						font-family: $poppinsregular;
						@include font-size(14,24);
						color:$grey;
						display: block;
						padding: 15.5px 27px 15.5px 65px;
						position: relative;
						@include transition;
						&:hover{
							background:#faf6fc;
						}
						@media (max-width:$md-width){
							padding: 15.5px 40px;
						}
						i{
							font-size: 16px;
							color:#b345e6;
							position: absolute;
							left: 30px;
							top: 20px;
							@media (max-width:$md-width){
								left:15px;
							}
						}
					}
				}
			}
		}
	}
}