.about-us{
    h1{
        font-family: $poppinsbold;
        @include font-size(25,32);
        color:$purple;
        margin-bottom: 20px;
    }
    p{
        font-family:$poppinsregular;
        @include font-size(14,22);
        color:#151515;
    }
}