.cookie-policy{
	background: rgba(109,59,133,0.9);
	padding: 19px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	display: none;
	&.active{
		display: block;
	}
	.container{
		position: relative;
	}
	p{
		font-family: $poppinslight;
		@include font-size(13,16);
		width: 80%;
		color:$white;
		@media (max-width:$md-width){
			width: 100%;
			padding-right: 50px;
		}
		a{
			color:$white;
			text-decoration: underline !important;
		}
	}
	a.cookie-close{
		display: inline-block;
		padding: 6px;
		background: $white;
		border-radius: 4px;
		position: absolute;
		right: 15px;
		top: 0;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 1;
		i{
			width: 17px;
            height: 17px;
            position: relative;
            transform:rotate(45deg);
            &:before{
                content:"";
                position: absolute;
                left:0;
                top: 50%;
                margin-top: -0.5px;
                width: 100%;
                height:1px;
                background-color: $purple;
            }
            &:after{
                content:"";
                position: absolute;
                left:50%;
                top: 0;
                margin-left: -0.5px;
                width: 1px;
                height:100%;
                background-color: $purple;
            }
		}
	}
}