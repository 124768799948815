.vertical-text{
    font-family: $poppinsregular;
    @include font-size(80,100);
    color:#e1e1ea;
    position: absolute;
    transform: rotate(-90deg);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #e1e1ea;
    z-index:9;
    @media (max-width:$md-width){
        @include font-size(40,60);
        top:20px;
        right:auto !important;
        left:-30px !important;
    }
    &.v1{
        right:0;
        top: 14%;
    }
    &.v2{
        left:-100px;
        top:7%;
        @media (max-width:$md-width){
            left:auto !important;
            right:-50px !important;
            top:8%;
        }
    }
}