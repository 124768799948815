header{
    padding:45px 0 62px 0;
    @media (max-width:$md-width){
        padding:15px 0;
        position:fixed;
        width: 100%;
        background-color: #4e2562;
        box-shadow: 0 0 15px rgba(255, 255,255, 0.1);
        z-index: 999;
    }
    .row{
        align-items: center;
    }
    nav{
        padding:0 !important;
        a{
            &.navbar-brand{
                img{
                    max-width: 378px;
                    width: 100%;
                    @media(max-width:$lg-width){
                        max-width: 278px;
                    }
                    @media(max-width:$mobile-width){
                        max-width: 228px;
                    }
                }
            }
        }
        button{
            &.navbar-toggler{
                width: 40px;
                height: 30px;
                padding:0;
                span{
                    &.navbar-toggler-icon{
                        position: relative;
                        background:$white;
                        width: 100%;
                        height:2px;
                        &:before{
                            content:"";
                            position: absolute;
                            left:0;
                            top: -10px;
                            width: 100%;
                            background:$white;
                            height: 2px;
                        }
                        &:after{
                            content:"";
                            position: absolute;
                            left:0;
                            bottom: -10px;
                            width: 100%;
                            background:$white;
                            height: 2px;
                        }
                    }
                }
            }
        }
        .navbar-collapse{
            justify-content: flex-end;
        }
        ul{
            &.navbar-nav{
                @media (max-width:$md-width){
                    padding-top: 20px;
                }
                li{
                    margin-right: 45px;
                    @media(max-width:$lg-width){
                        margin-right:15px;
                    }
                    @media(max-width:$md-width){
                        margin:0;
                    }
                    a{
                        font-family: $poppinssemibold;
                        @include font-size(13,19);
                        color:$white;
                        letter-spacing: 2px;
                        position: relative;
                        @media(max-width:$md-width){
                            padding:5px 0 !important;
                        }
                        &:before{
                            content:"";
                            position: absolute;
                            left:0;
                            bottom:-3px;
                            height:3px;
                            background:$purple;
                            @include transition;
                            width: 0;
                        }
                        &:hover{
                            &:before{
                                width:60%;
                                @media(max-width:$md-width){
                                    width:5%;
                                }
                            }
                        }
                    }
                    &.active{
                        a{
                            &:before{
                                width:60%;
                                @media(max-width:$md-width){
                                    width:5%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .phone-button{
        float: right;
        min-width: 200px;
        i{
            font-size: 30px;
            margin-right: 6px;
        }
    }
    &.sub-header{
        background: url(#{$images}/content/footer-bg.jpg) no-repeat center;
        background-size: cover;
        @media (max-width:$md-width){
            background: #4e2562;
        }
        .phone-button{
            background:none;
            border:1px solid $white;
        }
    }
    &.contact-header{
        background:none;
        position: absolute;
        width: 100%;
        @media (max-width:$md-width){
            box-shadow: 0 0 0 0;
        }
    }
}