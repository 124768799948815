//......................................
//= PATHS
//......................................

$images 				:	'../images';
$fonts					:	'../fonts';

$poppinsbold			:	'Conv_Poppins-Bold';
$poppinsmedium			:	'Conv_Poppins-Medium';
$poppinssemibold		:	'Conv_Poppins-SemiBold';
$poppinsregular			:	'Conv_Poppins-Regular';
$poppinslight			:	'Conv_Poppins-Light';

//COLORS		
$black					:	#000;
$white					:	#fff;
$darkpurple             :   #6d3b85;
$purple                 :   #8240a1;
$darkedpurple           :   #290948;
$grey                   :   #2a2a2a;

//BREAKPOINTS
$max-lg-width           : 1920px;
$middle-lg-width        : 1360px;
$lg-width				: 1200px;
$md-width				: 991px;
$sm-width				: 767px;
$xs-width				: 576px;

$mobile-width			: 375px;