footer{
	background: url(#{$images}/content/footer-bg.jpg) no-repeat center;
    background-size: cover;
	padding: 40px 0 0 0;
	@media (max-width:$md-width){
		padding-top: 10px;
	}
	> .container{
		> .row{
			> div{
				padding: 0 15px;
				float: left;
				&.col-erd-2{
					width: 14%;
				}
				&.col-erd-2-5{
					width: 19.5%;
				}
				&.col-erd-3{
					width: 22.25%;
				}
				&.col-erd-3-5{
					width: 22%;
				}
				@media (max-width:$md-width){
					width:100% !important;
				}
				> div{
					max-width: 300px;
					@media (max-width:$md-width){
						margin:15px auto;
						text-align: center;
					}
				}
			}
		}
	}
	h4{
		font-family: $poppinsbold;
		@include font-size(18,24);
		color:$white;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.footer-menu{
		ul{
			li{
				font-family: $poppinsregular;
				@include font-size(14,18);
				&:not(:last-child){
					margin-bottom: 22px;
					@media (max-width:$md-width){
						margin-bottom: 15px;
					}
				}
				a{
					font-family: $poppinsregular;
					@include font-size(14,18);
					color:$white;
                    i{
                        font-size: 20px;
                        margin-right: 8px;
                    }
				}
			}
		}
	}
	.e-bulten{
		@media (max-width:$md-width){
			margin:0 auto;
		}
		p{
			font-family: $poppinsregular;
			@include font-size(14,20);
			color:$white;
			margin-bottom: 35px;
		}
		.wrap{
			position: relative;
			input{
				padding: 6px 0;
				font-family: $poppinsregular;
				@include font-size(14,20);
				border-bottom: 2px solid $purple;
				width: 100%;
				color:$white;
				background:none;
				padding-right: 35px;
				@include placeholder{
					color:#ccc;
				}
			}
			button{
				background: $white;
				padding: 5px;
				position: absolute;
				right: 0;
				bottom: 6px;
				width: 26px;
				height: 26px;
				i{
					font-size: 14px;
					color:$purple;
					margin-top: -7px;
				}
			}
		}
	}
	.contact{
		address{
			margin-top: -11px;
			font-family: $poppinsregular;
			@include font-size(14,40);
			color:$white;
			margin-bottom: 17px;
		}
		ul{
			li{
				&:not(:last-child){
					margin-bottom: 14px;
				}
				a{
					font-family: $poppinsregular;
					@include font-size(14,18);
					color:$white;
					padding-left: 35px;
					position: relative;
					white-space: nowrap;
					i{
						position: absolute;
						left: 0;
						font-size: 24px;
						color:$white;
					}	
				}
			}
		}
	}
	.bottom-bar{
		margin-top: 46px;
        padding:10px 0;
		.row{
            display: flex;
            align-items: center;
			> div{
				@media (max-width:$md-width){
					padding:0;
				}
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(13,16);
			color: $white;
			@media (max-width:$md-width){
				padding:19px 0;
				text-align: center;
			}
		}
		a{
			&.comments{
				padding: 19px 15px;
				font-family: $poppinsregular;
				@include font-size(13,16);
				color:$white;
				background: $purple;
				display: block;
				text-align: center;
				i{
					font-size: 21px;
					color:$white;
					margin-right: 9px;
					margin-top: -2px;
				}
			}
		}
		ul{
			padding: 13px 0;
            display: flex;
            justify-content: flex-end;
            @media (max-width:$md-width){
                float: none;
                display:flex;
                align-items: center;
                justify-content: center;
            }
			li{
				float: left;
				display: inline-block;
				&:not(:last-child){
					padding-right: 25px;
				}
				a{
					i{
						font-size: 20px;
						color:$white;
					}
					img{
						height: 30px;
					}
				}
			}
		}
	}
}