[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow-spec:before {
    content: "\e900";
  }
  .icon-arrow:before {
    content: "\e901";
  }
  .icon-calendar:before {
    content: "\e902";
  }
  .icon-direction:before {
    content: "\e903";
  }
  .icon-facebook:before {
    content: "\e904";
  }
  .icon-fax:before {
    content: "\e905";
  }
  .icon-instagram:before {
    content: "\e906";
  }
  .icon-mail:before {
    content: "\e907";
  }
  .icon-pdf:before {
    content: "\e908";
  }
  .icon-phone:before {
    content: "\e909";
  }
  .icon-send:before {
    content: "\e90a";
  }
  .icon-share:before {
    content: "\e90b";
  }
  .icon-twitter:before {
    content: "\e90c";
  }
  .icon-word:before {
    content: "\e90d";
  }