.slide-bg{
    position: absolute;
    width: 100%;
    z-index: -1;
    .row{
        > div{
            height: 936px;
            &:first-child{
                @media (max-width: $middle-lg-width){
                    flex: 0 0 71%;
                    max-width: 71%;
                }
                @media (min-width: $max-lg-width){
                    flex: 0 0 62%;
                    max-width: 62%;
                }
                @media (max-width:$md-width){
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            figure{
                height: 100%;
                background:url(#{$images}/content/slide-bg.jpg) no-repeat center;
                background-size: cover;
                position: absolute;
                right:0;
                top: 0;
                left:0;
            }
            &:last-child{
                background:$white;
                @media (max-width: $middle-lg-width){
                    flex: 0 0 29%;
                    max-width: 29%;
                }
                @media (min-width: $max-lg-width){
                    flex: 0 0 38%;
                    max-width: 38%;
                }
                @media (max-width:$md-width){
                    display:none
                }
            }
        }
    }
}