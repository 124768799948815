.button-primary{
    font-family: $poppinssemibold;
    @include font-size(13,18);
    color:$white !important;
    background:$darkpurple;
    display: inline-block;
    padding:15px 20px;
    text-align: center;
    i{
        font-size: 19px;
        margin-right: 6px;
    }
}
.button-secondary{
    @extend .button-primary;
    background:none;
    border:1px solid $white;
}