.slider{
    position: relative;
    @media (max-width:$md-width){
        padding-top:120px;
    }
    &:before{
        content:"";
        position: absolute;
        right:0;
        width: 40%;
        background-color: #f3f3f3;
        height: 100%;
        @media (max-width:$md-width){
            display: none;
        }
    }
    .container{
        padding:0;
        .row{
            margin:0;
            align-items: center;
            > div{
                padding:0;
            }
        }
    }
    &__title{
        padding-left: 15px;
        @media (max-width:$md-width){
            margin-bottom: 50px;
            text-align: center;
            padding-left: 0;
        }
        p{
            font-family: $poppinslight;
            @include font-size(30,38);
            color:$white;
            @media (max-width:$md-width){
                @include font-size(25,32);
            }
            &.bold{
                font-family: $poppinssemibold;
                @include font-size(43,50);
                margin-bottom:10px;
                @media (max-width:$md-width){
                    @include font-size(35,40);
                }
            }
        }
        &_button{
            font-family: $poppinssemibold;
            @include font-size(13,18);
            color:$white !important;
            letter-spacing: 2px;
            display: inline-block;
            padding:15px 20px;
            border:2px solid $white;
            margin-top: 55px;
            @media (max-width:$md-width){
                margin-top: 30px;
            }
        }
    }
    &__figure{
        background:#f3f3f3;
        width: 100%;
        height: 750px;
        padding:100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:$md-width){
            height:auto;
            padding:30px;
            background:none;
        }
        figure{
            @media (max-width:$md-width){
                margin-bottom: 20px;
            }
            img{
                max-width: 100%;
                max-height: 400px;
               //width: 100%;
            }
        }
    }
}
#slide{
    ul{
	    max-width: 1140px;
	    padding: 0 15px;
	    width: 100%;
	    left: 0;
	    margin: 0 auto;
	    justify-content: flex-start;
	    bottom: 70px;
	    position: absolute;
	    @media (max-width: $lg-width){
	    	max-width: 960px;
	    }
	    @media (max-width: $md-width){
	    	max-width: 720px;
	    	justify-content:center;
            bottom: 10px;
	    }
	    @media (max-width: $sm-width){
	    	max-width: 540px;
	    }
	    li{
	    	width: auto;
	    	height: auto;
	    	text-indent: 0;
	    	background: none;
	    	font-family: $poppinsregular;
	    	@include font-size(16,03);
	    	color:$white;
	    	opacity: 1;
	    	margin:0 15px;
	    	position: relative;
	    	@include transition;
	    	@media (max-width: $sm-width){
	    		margin:0 8px;
	    	}
	    	&:before{
	    		content:"";
	    		position: absolute;
	    		left: 0;
	    		height: 1px;
	    		background: $purple;
	    		top: 50%;
	    		transform: translate(0,-50%);
	    		@include transition;
	    		width: 0;
	    	}
	    	&:first-child{
	    		margin-left:0;
	    	}
	    	&:last-child{
	    		margin-right:0;
	    	}
	    	&.active{
	    		color:$white;
	    		padding-left: 75px;
	    		@media (max-width: $sm-width){
    				padding-left: 55px;
    			}
	    		&:before{
	    			width:60px;
	    			@media (max-width: $sm-width){
	    				width: 40px;
	    			}
	    		}
	    	}
	    }
	}
    .carousel-item{
		.slider__title{
            p{
                opacity: 0;
                animation-duration: var(--animate-duration);
                animation-fill-mode: both;
            }
			a.slider__title_button{
				opacity: 0;
				animation-duration: var(--animate-duration);
				animation-fill-mode: both;
			}
		}
        figure{
            opacity: 0;
            animation-duration: var(--animate-duration);
            animation-fill-mode: both;
        }
		&.active{
            p{
                &.bold{
                    animation-name: fadeInLeft;
                    animation-duration: 1.5s;
                }
                &.regular{
                    animation-name: fadeInUp;
                    animation-delay: 1s;
                }
            }
			a.slider__title_button{
				animation-delay: 1.5s;
				animation-name: fadeIn;
			}
            figure{
                animation-name: fadeIn;
                animation-duration: 1.5s;
            }
		}
	}
}