.main-corporate{
    background:#efefef;
    .row{
        > div{
            padding:0;
        }
    }
    figure{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        z-index: 2;
        @media (max-width:$md-width){
            position: initial;
        }
        img{
            max-width: 100%;
            width: 100%;
        }
    }
    section{
        padding:70px 0 70px 100px;
        @media (max-width:$md-width){
            padding:50px 15px;
        }
        h2{
            font-family: $poppinsbold;
            @include font-size(35,48);
            color:$darkpurple;
            letter-spacing: 2px;
            margin-bottom:10px;
        }
        p{
            font-family: $poppinsregular;
            @include font-size(13,24);
            color:#290948;
            margin-bottom: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
        }
        a{
            font-family: $poppinsregular;
            @include font-size(11,20);
            letter-spacing: 1px;
        }
    }
}