.main-products{
    background: $purple url(#{$images}/content/main-products-bg.jpg) no-repeat;
    background-size:cover;
    padding:80px 0;
    @media (max-width:$md-width){
        padding:40px 0;
    }
    .row{
        > div{
            padding:0;
            @media (max-width:$md-width){
                padding:0 15px;
            }
        }
    }
    h3{
        font-family: $poppinsbold;
        @include font-size(35,48);
        color: $white;
        margin-bottom: 40px;
    }
    .swiper-wrapper{
        figure{
            width: 100%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 393px;
            margin-bottom: 20px;
            img{
                max-width: 100%;
            }
        }
        p{
            font-family: $poppinslight;
            @include font-size(20,30);
            color:$white;
            span{
                font-family: $poppinsbold;
            }
        }
    }
    .swiper-actions{
        display: flex;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        @media (max-width:$md-width){
            right:15px;
        }
        > div{
            &.swipe-cont{
                width: 41px;
                height: 41px;
                border:1px solid $white;
                position: initial;
                margin:0;
                background:none;
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include transition;
                &:before{
                    color:$white;
                    content:"\e901";
                    font-family: 'icomoon';
                    font-size: 20px;
                }
                &:after{
                    content:none;
                }
                &.product-swipe-button-prev{
                    &:before{
                        transform: rotate(180deg);
                    }
                }
            }
            .button-secondary{
                padding:10px 20px;
                @media (max-width:$sm-width){
                    display:none;
                }
            }
        }
    }
}