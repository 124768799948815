.announcements{
	.panel{
        border: 1px solid #ddd;
		+ .panel{
			margin-top: 15px;
		}
		border-radius: 0;
		.panel-heading{
			background: none;
			border-radius: 0;
			padding: 0;
			a{
				font-family: $poppinslight;
				@include font-size(15,24);
				color:$grey;
				display: block;
				position: relative;
				padding: 23px;
				padding-left: 216px;
				padding-right: 100px;
				@media (max-width:$md-width){
					padding:15px;
				}
				span{
					position: absolute;
					&.calendar{
						display: inline-block;
						width: 195px;
						padding: 23px 23px 23px 37px;
						left: 0;
						top: 0;
						height: 100%;
						border-right: 1px solid #ddd;
						@media (max-width:$md-width){
							position: initial;
							width: 100%;
							display: block;
							border-right: 0;
							padding: 0 0 15px 0;
							border-bottom: 1px solid #ddd;
							margin-bottom: 15px;

						}
						i{
							font-size: 19px;
							color:$purple;
							margin-right: 10px;
							margin-top: -3px;
						}
					}
					&.plus-minus{
						width: 75px;
						border-left: 1px solid #ddd;
						right: 0;
						top: 0;
						height: 100%;
						display:flex;
						align-items: center;
						justify-content: center;
						@media (max-width:$md-width){
							height: 55px;
						}
						i{
							width: 22px;
                            height:22px;
                            position: relative;
                            &:before,&:after{
                                content:"" !important;
                                display:inline-block;
                                position: absolute;
                                background:#ddd;
                            }
                            &:before{
                                width: 100%;
                                height:1px;
                                top: 50%;
                                margin-top: -0.5px;
                            }
                            &:after{
                                width: 1px;
                                height: 100%;
                                top: 0;
                                left: 50%;
                                margin-left: -0.5px;
                            }
						}
					}
				}
			}
		}
		.panel-body{
			padding: 44px 37px;
			font-family: $poppinslight;
			@include font-size(15,24);
			color:$grey;
            border-top:1px solid #ddd;
			@media (max-width:$md-width){
				padding: 20px 15px;
			}
		}
		&.active{
			.panel-heading{
				span{
					&.plus-minus{
						i{
							&:after{
                                opacity:0;
                            }
						}
					}
				}
			}
		}
	}
}