.main-brands{
    padding:60px 0;
    .row{
        > div{
            padding:0;
        }
    }
    figure{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 100%;
        }
    }
    .swiper-wrapper{
        align-items: center;
    }
}