.contact{
	max-width: 980px;
	margin:0 auto;
	.contact-information{
		h2{
			font-family: $poppinssemibold;
			@include font-size(40,50);
			color:$purple;
			@media (max-width:$md-width){
				@include font-size(35,45);
			}
		}
		address{
			font-family: $poppinslight;
			@include font-size(15,22);
			color:$grey;
			margin:27px 0 16px 0;
		}
		ul{
			li{
				&:not(:last-child){
					margin-bottom: 14px;
				}
				a{
					font-family: $poppinslight;
					@include font-size(15,22);
					color:$grey;
					position: relative;
					padding-left: 36px;
					i{
						font-size: 22px;
						color:$purple;
						position: absolute;
						left: 0;
					}
				}
			}
		}
	}
	.contact-form{
		max-width: 430px;
		margin:0 auto;
		@media (max-width:$md-width){
			margin:30px 0 0 0;
			max-width: 100%;
		}
		div{
			position: relative;
			padding-bottom: 35px;
			label{
				position: absolute;
				left: 0;
				bottom: 14px;
				width: 100%;
				font-family: $poppinsregular;
				@include font-size(12,14);
				&.error{
					color:red;
				}
			}
		}
		input,textarea{
			padding: 8px 0;
			font-family: $poppinslight;
			@include font-size(14,20);
			border-bottom: 1px solid #a2a2a2;
			width: 100%;
			color:$grey;
			background:none;
			@include placeholder{
				color:$grey;
				&:focus{
					color:$purple;
				}
			}
			&:focus{
				border-color:$purple;
			}
		}
		textarea{resize: none;height: 41px}
		button{
			font-family: $poppinsregular;
			@include font-size(18,24);
			color:$white;
			background: $purple;
			text-align: center;
			border-radius: 6px;
			width: 100%;
			padding: 18px 15px;
			margin-top: 30px;
			i{
				font-size: 22px;
				color:$white;
				margin-right: 9px;
				margin-top: -3px;
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(16,20);
			margin:15px 0;
			display: none;
			&.success{
				color:green;
				display: block;
			}
			&.error{
				color:red;
				display: block;
			}
		}
	}
}
#map{
	height: 530px;
	background:url(#{$images}/content/contact-map.jpg) no-repeat center;
	background-size: cover;
	@media (max-width:$md-width){
		height:300px
	}
}