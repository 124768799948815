@charset "UTF-8";
html {
  min-height: 100%;
  min-width: 100%;
  font-size: 62.5%; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  min-height: 100%;
  min-width: 100%;
  background: #ffffff; }

*::selection {
  background: #000000;
  color: #ffffff; }

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th, p, label, figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

input, select, textarea, button, a {
  outline: none !important;
  border: none; }

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

input, select, textarea {
  background: #ffffff;
  border-radius: 0; }

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type=text]::-ms-clear {
  display: none; }

i {
  display: inline-block;
  vertical-align: middle; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }

nav button.navbar-toggle {
  background: none !important;
  border: none !important; }

nav.navbar-expand-lg .navbar-nav a.nav-link {
  padding: 0;
  margin: 0; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown button {
  box-shadow: 0 0 0 0 !important;
  border: none;
  padding: 0; }

/*************************reset-erd*************************/
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-spec:before {
  content: "\e900"; }

.icon-arrow:before {
  content: "\e901"; }

.icon-calendar:before {
  content: "\e902"; }

.icon-direction:before {
  content: "\e903"; }

.icon-facebook:before {
  content: "\e904"; }

.icon-fax:before {
  content: "\e905"; }

.icon-instagram:before {
  content: "\e906"; }

.icon-mail:before {
  content: "\e907"; }

.icon-pdf:before {
  content: "\e908"; }

.icon-phone:before {
  content: "\e909"; }

.icon-send:before {
  content: "\e90a"; }

.icon-share:before {
  content: "\e90b"; }

.icon-twitter:before {
  content: "\e90c"; }

.icon-word:before {
  content: "\e90d"; }

@font-face {
  font-family: 'Conv_Poppins-Bold';
  src: url("../fonts/Poppins-Bold.eot");
  src: local("☺"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Light';
  src: url("../fonts/Poppins-Light.eot");
  src: local("☺"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: local("☺"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Regular';
  src: url("../fonts/Poppins-Regular.eot");
  src: local("☺"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-SemiBold';
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("☺"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?o47ne5");
  src: url("../fonts/icomoon.eot?o47ne5#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?o47ne5") format("truetype"), url("../fonts/icomoon.woff?o47ne5") format("woff"), url("../fonts/icomoon.svg?o47ne5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

header {
  padding: 45px 0 62px 0; }
  @media (max-width: 991px) {
    header {
      padding: 15px 0;
      position: fixed;
      width: 100%;
      background-color: #4e2562;
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
      z-index: 999; } }
  header .row {
    align-items: center; }
  header nav {
    padding: 0 !important; }
    header nav a.navbar-brand img {
      max-width: 378px;
      width: 100%; }
      @media (max-width: 1200px) {
        header nav a.navbar-brand img {
          max-width: 278px; } }
      @media (max-width: 375px) {
        header nav a.navbar-brand img {
          max-width: 228px; } }
    header nav button.navbar-toggler {
      width: 40px;
      height: 30px;
      padding: 0; }
      header nav button.navbar-toggler span.navbar-toggler-icon {
        position: relative;
        background: #fff;
        width: 100%;
        height: 2px; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before {
          content: "";
          position: absolute;
          left: 0;
          top: -10px;
          width: 100%;
          background: #fff;
          height: 2px; }
        header nav button.navbar-toggler span.navbar-toggler-icon:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 100%;
          background: #fff;
          height: 2px; }
    header nav .navbar-collapse {
      justify-content: flex-end; }
    @media (max-width: 991px) {
      header nav ul.navbar-nav {
        padding-top: 20px; } }
    header nav ul.navbar-nav li {
      margin-right: 45px; }
      @media (max-width: 1200px) {
        header nav ul.navbar-nav li {
          margin-right: 15px; } }
      @media (max-width: 991px) {
        header nav ul.navbar-nav li {
          margin: 0; } }
      header nav ul.navbar-nav li a {
        font-family: "Conv_Poppins-SemiBold";
        font-size: 1.3rem;
        line-height: 1.9rem;
        color: #fff;
        letter-spacing: 2px;
        position: relative; }
        @media (max-width: 991px) {
          header nav ul.navbar-nav li a {
            padding: 5px 0 !important; } }
        header nav ul.navbar-nav li a:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3px;
          height: 3px;
          background: #8240a1;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out;
          width: 0; }
        header nav ul.navbar-nav li a:hover:before {
          width: 60%; }
          @media (max-width: 991px) {
            header nav ul.navbar-nav li a:hover:before {
              width: 5%; } }
      header nav ul.navbar-nav li.active a:before {
        width: 60%; }
        @media (max-width: 991px) {
          header nav ul.navbar-nav li.active a:before {
            width: 5%; } }
  header .phone-button {
    float: right;
    min-width: 200px; }
    header .phone-button i {
      font-size: 30px;
      margin-right: 6px; }
  header.sub-header {
    background: url(../images/content/footer-bg.jpg) no-repeat center;
    background-size: cover; }
    @media (max-width: 991px) {
      header.sub-header {
        background: #4e2562; } }
    header.sub-header .phone-button {
      background: none;
      border: 1px solid #fff; }
  header.contact-header {
    background: none;
    position: absolute;
    width: 100%; }
    @media (max-width: 991px) {
      header.contact-header {
        box-shadow: 0 0 0 0; } }

.button-primary, .button-secondary {
  font-family: "Conv_Poppins-SemiBold";
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #fff !important;
  background: #6d3b85;
  display: inline-block;
  padding: 15px 20px;
  text-align: center; }
  .button-primary i, .button-secondary i {
    font-size: 19px;
    margin-right: 6px; }

.button-secondary {
  background: none;
  border: 1px solid #fff; }

footer {
  background: url(../images/content/footer-bg.jpg) no-repeat center;
  background-size: cover;
  padding: 40px 0 0 0; }
  @media (max-width: 991px) {
    footer {
      padding-top: 10px; } }
  footer > .container > .row > div {
    padding: 0 15px;
    float: left; }
    footer > .container > .row > div.col-erd-2 {
      width: 14%; }
    footer > .container > .row > div.col-erd-2-5 {
      width: 19.5%; }
    footer > .container > .row > div.col-erd-3 {
      width: 22.25%; }
    footer > .container > .row > div.col-erd-3-5 {
      width: 22%; }
    @media (max-width: 991px) {
      footer > .container > .row > div {
        width: 100% !important; } }
    footer > .container > .row > div > div {
      max-width: 300px; }
      @media (max-width: 991px) {
        footer > .container > .row > div > div {
          margin: 15px auto;
          text-align: center; } }
  footer h4 {
    font-family: "Conv_Poppins-Bold";
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase; }
  footer .footer-menu ul li {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 1.8rem; }
    footer .footer-menu ul li:not(:last-child) {
      margin-bottom: 22px; }
      @media (max-width: 991px) {
        footer .footer-menu ul li:not(:last-child) {
          margin-bottom: 15px; } }
    footer .footer-menu ul li a {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #fff; }
      footer .footer-menu ul li a i {
        font-size: 20px;
        margin-right: 8px; }
  @media (max-width: 991px) {
    footer .e-bulten {
      margin: 0 auto; } }
  footer .e-bulten p {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 2rem;
    color: #fff;
    margin-bottom: 35px; }
  footer .e-bulten .wrap {
    position: relative; }
    footer .e-bulten .wrap input {
      padding: 6px 0;
      font-family: "Conv_Poppins-Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      border-bottom: 2px solid #8240a1;
      width: 100%;
      color: #fff;
      background: none;
      padding-right: 35px; }
      footer .e-bulten .wrap input::-webkit-input-placeholder {
        color: #ccc; }
      footer .e-bulten .wrap input:-moz-placeholder {
        color: #ccc; }
      footer .e-bulten .wrap input::-moz-placeholder {
        color: #ccc; }
      footer .e-bulten .wrap input:-ms-input-placeholder {
        color: #ccc; }
    footer .e-bulten .wrap button {
      background: #fff;
      padding: 5px;
      position: absolute;
      right: 0;
      bottom: 6px;
      width: 26px;
      height: 26px; }
      footer .e-bulten .wrap button i {
        font-size: 14px;
        color: #8240a1;
        margin-top: -7px; }
  footer .contact address {
    margin-top: -11px;
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 4rem;
    color: #fff;
    margin-bottom: 17px; }
  footer .contact ul li:not(:last-child) {
    margin-bottom: 14px; }
  footer .contact ul li a {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #fff;
    padding-left: 35px;
    position: relative;
    white-space: nowrap; }
    footer .contact ul li a i {
      position: absolute;
      left: 0;
      font-size: 24px;
      color: #fff; }
  footer .bottom-bar {
    margin-top: 46px;
    padding: 10px 0; }
    footer .bottom-bar .row {
      display: flex;
      align-items: center; }
      @media (max-width: 991px) {
        footer .bottom-bar .row > div {
          padding: 0; } }
    footer .bottom-bar p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #fff; }
      @media (max-width: 991px) {
        footer .bottom-bar p {
          padding: 19px 0;
          text-align: center; } }
    footer .bottom-bar a.comments {
      padding: 19px 15px;
      font-family: "Conv_Poppins-Regular";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #fff;
      background: #8240a1;
      display: block;
      text-align: center; }
      footer .bottom-bar a.comments i {
        font-size: 21px;
        color: #fff;
        margin-right: 9px;
        margin-top: -2px; }
    footer .bottom-bar ul {
      padding: 13px 0;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 991px) {
        footer .bottom-bar ul {
          float: none;
          display: flex;
          align-items: center;
          justify-content: center; } }
      footer .bottom-bar ul li {
        float: left;
        display: inline-block; }
        footer .bottom-bar ul li:not(:last-child) {
          padding-right: 25px; }
        footer .bottom-bar ul li a i {
          font-size: 20px;
          color: #fff; }
        footer .bottom-bar ul li a img {
          height: 30px; }

.slide-bg {
  position: absolute;
  width: 100%;
  z-index: -1; }
  .slide-bg .row > div {
    height: 936px; }
    @media (max-width: 1360px) {
      .slide-bg .row > div:first-child {
        flex: 0 0 71%;
        max-width: 71%; } }
    @media (min-width: 1920px) {
      .slide-bg .row > div:first-child {
        flex: 0 0 62%;
        max-width: 62%; } }
    @media (max-width: 991px) {
      .slide-bg .row > div:first-child {
        flex: 0 0 100%;
        max-width: 100%; } }
    .slide-bg .row > div figure {
      height: 100%;
      background: url(../images/content/slide-bg.jpg) no-repeat center;
      background-size: cover;
      position: absolute;
      right: 0;
      top: 0;
      left: 0; }
    .slide-bg .row > div:last-child {
      background: #fff; }
      @media (max-width: 1360px) {
        .slide-bg .row > div:last-child {
          flex: 0 0 29%;
          max-width: 29%; } }
      @media (min-width: 1920px) {
        .slide-bg .row > div:last-child {
          flex: 0 0 38%;
          max-width: 38%; } }
      @media (max-width: 991px) {
        .slide-bg .row > div:last-child {
          display: none; } }

.slider {
  position: relative; }
  @media (max-width: 991px) {
    .slider {
      padding-top: 120px; } }
  .slider:before {
    content: "";
    position: absolute;
    right: 0;
    width: 40%;
    background-color: #f3f3f3;
    height: 100%; }
    @media (max-width: 991px) {
      .slider:before {
        display: none; } }
  .slider .container {
    padding: 0; }
    .slider .container .row {
      margin: 0;
      align-items: center; }
      .slider .container .row > div {
        padding: 0; }
  .slider__title {
    padding-left: 15px; }
    @media (max-width: 991px) {
      .slider__title {
        margin-bottom: 50px;
        text-align: center;
        padding-left: 0; } }
    .slider__title p {
      font-family: "Conv_Poppins-Light";
      font-size: 3rem;
      line-height: 3.8rem;
      color: #fff; }
      @media (max-width: 991px) {
        .slider__title p {
          font-size: 2.5rem;
          line-height: 3.2rem; } }
      .slider__title p.bold {
        font-family: "Conv_Poppins-SemiBold";
        font-size: 4.3rem;
        line-height: 5rem;
        margin-bottom: 10px; }
        @media (max-width: 991px) {
          .slider__title p.bold {
            font-size: 3.5rem;
            line-height: 4rem; } }
    .slider__title_button {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: #fff !important;
      letter-spacing: 2px;
      display: inline-block;
      padding: 15px 20px;
      border: 2px solid #fff;
      margin-top: 55px; }
      @media (max-width: 991px) {
        .slider__title_button {
          margin-top: 30px; } }
  .slider__figure {
    background: #f3f3f3;
    width: 100%;
    height: 750px;
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .slider__figure {
        height: auto;
        padding: 30px;
        background: none; } }
    @media (max-width: 991px) {
      .slider__figure figure {
        margin-bottom: 20px; } }
    .slider__figure figure img {
      max-width: 100%;
      max-height: 400px; }

#slide ul {
  max-width: 1140px;
  padding: 0 15px;
  width: 100%;
  left: 0;
  margin: 0 auto;
  justify-content: flex-start;
  bottom: 70px;
  position: absolute; }
  @media (max-width: 1200px) {
    #slide ul {
      max-width: 960px; } }
  @media (max-width: 991px) {
    #slide ul {
      max-width: 720px;
      justify-content: center;
      bottom: 10px; } }
  @media (max-width: 767px) {
    #slide ul {
      max-width: 540px; } }
  #slide ul li {
    width: auto;
    height: auto;
    text-indent: 0;
    background: none;
    font-family: "Conv_Poppins-Regular";
    font-size: 1.6rem;
    line-height: 0.3rem;
    color: #fff;
    opacity: 1;
    margin: 0 15px;
    position: relative;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
    @media (max-width: 767px) {
      #slide ul li {
        margin: 0 8px; } }
    #slide ul li:before {
      content: "";
      position: absolute;
      left: 0;
      height: 1px;
      background: #8240a1;
      top: 50%;
      transform: translate(0, -50%);
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out;
      width: 0; }
    #slide ul li:first-child {
      margin-left: 0; }
    #slide ul li:last-child {
      margin-right: 0; }
    #slide ul li.active {
      color: #fff;
      padding-left: 75px; }
      @media (max-width: 767px) {
        #slide ul li.active {
          padding-left: 55px; } }
      #slide ul li.active:before {
        width: 60px; }
        @media (max-width: 767px) {
          #slide ul li.active:before {
            width: 40px; } }

#slide .carousel-item .slider__title p {
  opacity: 0;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both; }

#slide .carousel-item .slider__title a.slider__title_button {
  opacity: 0;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both; }

#slide .carousel-item figure {
  opacity: 0;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both; }

#slide .carousel-item.active p.bold {
  animation-name: fadeInLeft;
  animation-duration: 1.5s; }

#slide .carousel-item.active p.regular {
  animation-name: fadeInUp;
  animation-delay: 1s; }

#slide .carousel-item.active a.slider__title_button {
  animation-delay: 1.5s;
  animation-name: fadeIn; }

#slide .carousel-item.active figure {
  animation-name: fadeIn;
  animation-duration: 1.5s; }

.vertical-text {
  font-family: "Conv_Poppins-Regular";
  font-size: 8rem;
  line-height: 10rem;
  color: #e1e1ea;
  position: absolute;
  transform: rotate(-90deg);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1ea;
  z-index: 9; }
  @media (max-width: 991px) {
    .vertical-text {
      font-size: 4rem;
      line-height: 6rem;
      top: 20px;
      right: auto !important;
      left: -30px !important; } }
  .vertical-text.v1 {
    right: 0;
    top: 14%; }
  .vertical-text.v2 {
    left: -100px;
    top: 7%; }
    @media (max-width: 991px) {
      .vertical-text.v2 {
        left: auto !important;
        right: -50px !important;
        top: 8%; } }

.main-corporate {
  background: #efefef; }
  .main-corporate .row > div {
    padding: 0; }
  .main-corporate figure {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
    @media (max-width: 991px) {
      .main-corporate figure {
        position: initial; } }
    .main-corporate figure img {
      max-width: 100%;
      width: 100%; }
  .main-corporate section {
    padding: 70px 0 70px 100px; }
    @media (max-width: 991px) {
      .main-corporate section {
        padding: 50px 15px; } }
    .main-corporate section h2 {
      font-family: "Conv_Poppins-Bold";
      font-size: 3.5rem;
      line-height: 4.8rem;
      color: #6d3b85;
      letter-spacing: 2px;
      margin-bottom: 10px; }
    .main-corporate section p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.3rem;
      line-height: 2.4rem;
      color: #290948;
      margin-bottom: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden; }
    .main-corporate section a {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.1rem;
      line-height: 2rem;
      letter-spacing: 1px; }

.main-announcements {
  margin-top: 161px;
  position: relative; }
  @media (max-width: 1200px) {
    .main-announcements {
      margin-top: 69px; } }
  @media (max-width: 991px) {
    .main-announcements {
      margin-top: 0;
      padding-bottom: 30px; } }
  .main-announcements:before {
    content: "";
    position: absolute;
    right: 0;
    top: -1px;
    width: 58%;
    height: 1px;
    background-color: #e9e9e9; }
    @media (max-width: 991px) {
      .main-announcements:before {
        display: none; } }
  .main-announcements .container {
    position: relative; }
    .main-announcements .container:before {
      content: "";
      position: absolute;
      right: -1px;
      width: 1px;
      height: 100%;
      top: 0;
      background-color: #e9e9e9; }
      @media (max-width: 991px) {
        .main-announcements .container:before {
          display: none; } }
    .main-announcements .container:after {
      content: "";
      position: absolute;
      right: -1px;
      width: 1px;
      height: 161px;
      top: -161px;
      background-color: #e9e9e9; }
      @media (max-width: 991px) {
        .main-announcements .container:after {
          display: none; } }
  .main-announcements .row > div {
    padding: 0; }
    @media (max-width: 991px) {
      .main-announcements .row > div {
        padding: 0 15px; } }
    @media (max-width: 991px) {
      .main-announcements .row > div:first-child {
        order: 2; } }
  .main-announcements section {
    padding: 60px 15px 0 0; }
    @media (max-width: 991px) {
      .main-announcements section {
        padding: 0; } }
    .main-announcements section h2 {
      font-family: "Conv_Poppins-Bold";
      font-size: 3.5rem;
      line-height: 4.8rem;
      color: #6d3b85;
      letter-spacing: 2px;
      margin-bottom: 30px; }
      @media (max-width: 991px) {
        .main-announcements section h2 {
          margin-bottom: 0; } }
    .main-announcements section .swiper {
      margin-bottom: 66px; }
      @media (max-width: 991px) {
        .main-announcements section .swiper {
          margin-bottom: 30px; } }
      .main-announcements section .swiper .swiper-wrapper {
        height: 410px; }
      .main-announcements section .swiper .swiper-slide {
        border-bottom: 1px solid #efefef; }
    .main-announcements section .swiper-actions {
      display: flex;
      justify-content: flex-start; }
      .main-announcements section .swiper-actions > div.swipe-cont {
        width: 49px;
        height: 49px;
        border: 1px solid #6d3b85;
        position: initial;
        margin: 0;
        background: none;
        margin-right: 5px;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
        .main-announcements section .swiper-actions > div.swipe-cont:before {
          color: #6d3b85; }
        .main-announcements section .swiper-actions > div.swipe-cont:after {
          content: none; }
        .main-announcements section .swiper-actions > div.swipe-cont.swiper-button-prev:before {
          content: "\e901";
          font-family: 'icomoon';
          font-size: 25px;
          transform: rotate(270deg); }
        .main-announcements section .swiper-actions > div.swipe-cont.swiper-button-next:before {
          content: "\e901";
          font-family: 'icomoon';
          font-size: 25px;
          transform: rotate(90deg); }
    .main-announcements section .slide-announcements {
      padding: 22px 0; }
      .main-announcements section .slide-announcements span {
        font-family: "Conv_Poppins-Regular";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #290948;
        margin-bottom: 15px;
        display: block; }
        .main-announcements section .slide-announcements span i {
          font-size: 27px;
          margin-right: 6px; }
          .main-announcements section .slide-announcements span i:before {
            color: #6d3b85; }
      .main-announcements section .slide-announcements p {
        font-family: "Conv_Poppins-Regular";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #290948;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden; }
  @media (max-width: 991px) {
    .main-announcements figure {
      margin-bottom: 40px; } }
  .main-announcements figure img {
    max-width: 100%;
    width: 100%; }

.main-products {
  background: #8240a1 url(../images/content/main-products-bg.jpg) no-repeat;
  background-size: cover;
  padding: 80px 0; }
  @media (max-width: 991px) {
    .main-products {
      padding: 40px 0; } }
  .main-products .row > div {
    padding: 0; }
    @media (max-width: 991px) {
      .main-products .row > div {
        padding: 0 15px; } }
  .main-products h3 {
    font-family: "Conv_Poppins-Bold";
    font-size: 3.5rem;
    line-height: 4.8rem;
    color: #fff;
    margin-bottom: 40px; }
  .main-products .swiper-wrapper figure {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 393px;
    margin-bottom: 20px; }
    .main-products .swiper-wrapper figure img {
      max-width: 100%; }
  .main-products .swiper-wrapper p {
    font-family: "Conv_Poppins-Light";
    font-size: 2rem;
    line-height: 3rem;
    color: #fff; }
    .main-products .swiper-wrapper p span {
      font-family: "Conv_Poppins-Bold"; }
  .main-products .swiper-actions {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    right: 0;
    top: 0; }
    @media (max-width: 991px) {
      .main-products .swiper-actions {
        right: 15px; } }
    .main-products .swiper-actions > div.swipe-cont {
      width: 41px;
      height: 41px;
      border: 1px solid #fff;
      position: initial;
      margin: 0;
      background: none;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .main-products .swiper-actions > div.swipe-cont:before {
        color: #fff;
        content: "\e901";
        font-family: 'icomoon';
        font-size: 20px; }
      .main-products .swiper-actions > div.swipe-cont:after {
        content: none; }
      .main-products .swiper-actions > div.swipe-cont.product-swipe-button-prev:before {
        transform: rotate(180deg); }
    .main-products .swiper-actions > div .button-secondary {
      padding: 10px 20px; }
      @media (max-width: 767px) {
        .main-products .swiper-actions > div .button-secondary {
          display: none; } }

.main-brands {
  padding: 60px 0; }
  .main-brands .row > div {
    padding: 0; }
  .main-brands figure {
    display: flex;
    align-items: center;
    justify-content: center; }
    .main-brands figure img {
      max-width: 100%; }
  .main-brands .swiper-wrapper {
    align-items: center; }

.wrapper {
  padding: 60px 0;
  background-color: #f8f8f8; }
  @media (max-width: 991px) {
    .wrapper {
      padding: 90px 0 40px 0; } }

.about-us h1 {
  font-family: "Conv_Poppins-Bold";
  font-size: 2.5rem;
  line-height: 3.2rem;
  color: #8240a1;
  margin-bottom: 20px; }

.about-us p {
  font-family: "Conv_Poppins-Regular";
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #151515; }

.products .products-menu .mobile-menu-title {
  display: none;
  margin-bottom: 15px;
  cursor: pointer; }
  @media (max-width: 991px) {
    .products .products-menu .mobile-menu-title {
      display: block; } }
  .products .products-menu .mobile-menu-title h3 {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #fff;
    padding: 17px 20px;
    border: 1px solid #8240a1;
    background: #8240a1;
    position: relative;
    padding-right: 45px; }
    .products .products-menu .mobile-menu-title h3:before {
      content: "\e901";
      font-family: 'icomoon';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%) rotate(90deg);
      color: #fff;
      font-size: 14px; }

.products .products-menu .panel-group {
  margin: 0; }
  .products .products-menu .panel-group .panel-heading {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0; }

.products .products-menu .main-panel-group {
  display: block; }
  @media (max-width: 991px) {
    .products .products-menu .main-panel-group {
      display: none;
      margin-bottom: 15px; }
      .products .products-menu .main-panel-group.active {
        display: block; } }
  .products .products-menu .main-panel-group > .panel-default {
    border-radius: 0;
    box-shadow: 0 0 0 0;
    border: 1px solid #d9d9d9;
    background: #fff; }
    .products .products-menu .main-panel-group > .panel-default + .panel {
      margin-top: 9px; }
    .products .products-menu .main-panel-group > .panel-default > .panel-heading a {
      display: block;
      font-family: "Conv_Poppins-Regular";
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #2a2a2a;
      background: #fff;
      padding: 16px 35px 16px 20px;
      position: relative;
      text-transform: uppercase; }
      .products .products-menu .main-panel-group > .panel-default > .panel-heading a:before {
        content: "";
        position: absolute;
        right: 20px;
        background: #cccccc;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 1px; }
      .products .products-menu .main-panel-group > .panel-default > .panel-heading a:after {
        content: "";
        position: absolute;
        right: 27px;
        background: #cccccc;
        top: 50%;
        width: 1px;
        height: 15px;
        margin-top: -7.5px;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
        opacity: 1; }
      .products .products-menu .main-panel-group > .panel-default > .panel-heading a[aria-expanded=true]:after {
        opacity: 0; }
    .products .products-menu .main-panel-group > .panel-default .panel-body {
      padding: 0;
      border-top: 1px solid #d9d9d9; }
      .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default {
        border: none;
        margin: 0 !important; }
        .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default:not(:last-child) {
          border-bottom: 1px solid #dddddd; }
        .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a {
          display: block;
          font-family: "Conv_Poppins-Light";
          font-size: 1.4rem;
          line-height: 2rem;
          color: #2a2a2a;
          padding: 13.5px 15px 13.5px 43px;
          position: relative;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:before {
            content: "\e901";
            font-family: 'icomoon';
            font-size: 6px;
            color: #dddddd;
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translate(0, -50%);
            -webkit-transition: .4s ease-in-out;
            -moz-transition: .4s ease-in-out;
            -ms-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
            transition: .4s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 0;
            background: #8240a1;
            -webkit-transition: .4s ease-in-out;
            -moz-transition: .4s ease-in-out;
            -ms-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
            transition: .4s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active {
            background: #faf6fc; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover:before, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active:before {
              color: #8240a1; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a:hover:after, .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a.active:after {
              height: 100%; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a i {
            position: absolute;
            right: 20px;
            color: #dddddd;
            width: 15px;
            height: 15px; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a i:before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              width: 15px;
              height: 1px;
              background: #ccc;
              transform: translate(0, -50%);
              -webkit-transition: .4s ease-in-out;
              -moz-transition: .4s ease-in-out;
              -ms-transition: .4s ease-in-out;
              -o-transition: .4s ease-in-out;
              transition: .4s ease-in-out; }
            .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a i:after {
              content: "";
              position: absolute;
              left: 50%;
              margin-left: -1px;
              top: 0;
              width: 1px;
              height: 15px;
              background: #ccc;
              -webkit-transition: .4s ease-in-out;
              -moz-transition: .4s ease-in-out;
              -ms-transition: .4s ease-in-out;
              -o-transition: .4s ease-in-out;
              transition: .4s ease-in-out; }
          .products .products-menu .main-panel-group > .panel-default .panel-body > .panel-group .panel-default .panel-heading a[aria-expanded=true] i:after {
            opacity: 0; }
      .products .products-menu .main-panel-group > .panel-default .panel-body ul li:not(:last-child) {
        border-bottom: 1px solid #dddddd; }
      .products .products-menu .main-panel-group > .panel-default .panel-body ul li a {
        font-family: "Conv_Poppins-Light";
        font-size: 1.4rem;
        line-height: 2rem;
        color: #2a2a2a;
        padding: 10px 10px 10px 52px;
        display: block;
        position: relative;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
        .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:before {
          content: "\e901";
          font-family: 'icomoon';
          font-size: 9px;
          color: #dddddd;
          position: absolute;
          left: 35px;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:hover, .products .products-menu .main-panel-group > .panel-default .panel-body ul li a.active {
          color: #fff;
          background: #8240a1; }
          .products .products-menu .main-panel-group > .panel-default .panel-body ul li a:hover:before, .products .products-menu .main-panel-group > .panel-default .panel-body ul li a.active:before {
            color: #fff; }

.products .product-detail .title {
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 15.5px 27px;
  display: table;
  width: 100%; }
  @media (max-width: 991px) {
    .products .product-detail .title {
      padding: 15.5px 15px; } }
  .products .product-detail .title h3 {
    font-family: "Conv_Poppins-Bold";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #2a2a2a;
    display: inline-block;
    float: left;
    text-transform: uppercase; }
  .products .product-detail .title span {
    display: inline-block;
    float: left;
    font-family: "Conv_Poppins-Light";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #2a2a2a;
    position: relative;
    padding-left: 14px; }
    .products .product-detail .title span:before {
      content: "/";
      position: absolute;
      left: 5px; }

.products .product-detail figure {
  margin-top: 9px;
  border: 1px solid #d9d9d9;
  background: #fff;
  height: 292px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .products .product-detail figure img {
    max-width: 100%;
    max-height: 100%; }

.products .product-detail section {
  background: #fff;
  border: 1px solid #d9d9d9;
  margin-top: 9px; }
  .products .product-detail section h3 {
    font-family: "Conv_Poppins-Bold";
    font-size: 1.7rem;
    line-height: 2.4rem;
    color: #2a2a2a;
    padding: 14px 27px;
    border-bottom: 1px solid #d9d9d9; }
    @media (max-width: 991px) {
      .products .product-detail section h3 {
        padding: 14px 15px; } }
  .products .product-detail section article {
    padding: 41px 27px;
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #2a2a2a; }
    @media (max-width: 991px) {
      .products .product-detail section article {
        padding: 20px; } }
    .products .product-detail section article img {
      max-width: 100%;
      height: auto !important; }
  .products .product-detail section ul li:not(:last-child) {
    border-bottom: 1px solid #d9d9d9; }
  .products .product-detail section ul li a {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #2a2a2a;
    display: block;
    padding: 15.5px 27px 15.5px 65px;
    position: relative;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
    .products .product-detail section ul li a:hover {
      background: #faf6fc; }
    @media (max-width: 991px) {
      .products .product-detail section ul li a {
        padding: 15.5px 40px; } }
    .products .product-detail section ul li a i {
      font-size: 16px;
      color: #b345e6;
      position: absolute;
      left: 30px;
      top: 20px; }
      @media (max-width: 991px) {
        .products .product-detail section ul li a i {
          left: 15px; } }

.contact {
  max-width: 980px;
  margin: 0 auto; }
  .contact .contact-information h2 {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 4rem;
    line-height: 5rem;
    color: #8240a1; }
    @media (max-width: 991px) {
      .contact .contact-information h2 {
        font-size: 3.5rem;
        line-height: 4.5rem; } }
  .contact .contact-information address {
    font-family: "Conv_Poppins-Light";
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #2a2a2a;
    margin: 27px 0 16px 0; }
  .contact .contact-information ul li:not(:last-child) {
    margin-bottom: 14px; }
  .contact .contact-information ul li a {
    font-family: "Conv_Poppins-Light";
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #2a2a2a;
    position: relative;
    padding-left: 36px; }
    .contact .contact-information ul li a i {
      font-size: 22px;
      color: #8240a1;
      position: absolute;
      left: 0; }
  .contact .contact-form {
    max-width: 430px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      .contact .contact-form {
        margin: 30px 0 0 0;
        max-width: 100%; } }
    .contact .contact-form div {
      position: relative;
      padding-bottom: 35px; }
      .contact .contact-form div label {
        position: absolute;
        left: 0;
        bottom: 14px;
        width: 100%;
        font-family: "Conv_Poppins-Regular";
        font-size: 1.2rem;
        line-height: 1.4rem; }
        .contact .contact-form div label.error {
          color: red; }
    .contact .contact-form input, .contact .contact-form textarea {
      padding: 8px 0;
      font-family: "Conv_Poppins-Light";
      font-size: 1.4rem;
      line-height: 2rem;
      border-bottom: 1px solid #a2a2a2;
      width: 100%;
      color: #2a2a2a;
      background: none; }
      .contact .contact-form input::-webkit-input-placeholder, .contact .contact-form textarea::-webkit-input-placeholder {
        color: #2a2a2a; }
        .contact .contact-form input::-webkit-input-placeholder:focus, .contact .contact-form textarea::-webkit-input-placeholder:focus {
          color: #8240a1; }
      .contact .contact-form input:-moz-placeholder, .contact .contact-form textarea:-moz-placeholder {
        color: #2a2a2a; }
        .contact .contact-form input:-moz-placeholder:focus, .contact .contact-form textarea:-moz-placeholder:focus {
          color: #8240a1; }
      .contact .contact-form input::-moz-placeholder, .contact .contact-form textarea::-moz-placeholder {
        color: #2a2a2a; }
        .contact .contact-form input::-moz-placeholder:focus, .contact .contact-form textarea::-moz-placeholder:focus {
          color: #8240a1; }
      .contact .contact-form input:-ms-input-placeholder, .contact .contact-form textarea:-ms-input-placeholder {
        color: #2a2a2a; }
        .contact .contact-form input:-ms-input-placeholder:focus, .contact .contact-form textarea:-ms-input-placeholder:focus {
          color: #8240a1; }
      .contact .contact-form input:focus, .contact .contact-form textarea:focus {
        border-color: #8240a1; }
    .contact .contact-form textarea {
      resize: none;
      height: 41px; }
    .contact .contact-form button {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: #fff;
      background: #8240a1;
      text-align: center;
      border-radius: 6px;
      width: 100%;
      padding: 18px 15px;
      margin-top: 30px; }
      .contact .contact-form button i {
        font-size: 22px;
        color: #fff;
        margin-right: 9px;
        margin-top: -3px; }
    .contact .contact-form p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 15px 0;
      display: none; }
      .contact .contact-form p.success {
        color: green;
        display: block; }
      .contact .contact-form p.error {
        color: red;
        display: block; }

#map {
  height: 530px;
  background: url(../images/content/contact-map.jpg) no-repeat center;
  background-size: cover; }
  @media (max-width: 991px) {
    #map {
      height: 300px; } }

.announcements .panel {
  border: 1px solid #ddd;
  border-radius: 0; }
  .announcements .panel + .panel {
    margin-top: 15px; }
  .announcements .panel .panel-heading {
    background: none;
    border-radius: 0;
    padding: 0; }
    .announcements .panel .panel-heading a {
      font-family: "Conv_Poppins-Light";
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: #2a2a2a;
      display: block;
      position: relative;
      padding: 23px;
      padding-left: 216px;
      padding-right: 100px; }
      @media (max-width: 991px) {
        .announcements .panel .panel-heading a {
          padding: 15px; } }
      .announcements .panel .panel-heading a span {
        position: absolute; }
        .announcements .panel .panel-heading a span.calendar {
          display: inline-block;
          width: 195px;
          padding: 23px 23px 23px 37px;
          left: 0;
          top: 0;
          height: 100%;
          border-right: 1px solid #ddd; }
          @media (max-width: 991px) {
            .announcements .panel .panel-heading a span.calendar {
              position: initial;
              width: 100%;
              display: block;
              border-right: 0;
              padding: 0 0 15px 0;
              border-bottom: 1px solid #ddd;
              margin-bottom: 15px; } }
          .announcements .panel .panel-heading a span.calendar i {
            font-size: 19px;
            color: #8240a1;
            margin-right: 10px;
            margin-top: -3px; }
        .announcements .panel .panel-heading a span.plus-minus {
          width: 75px;
          border-left: 1px solid #ddd;
          right: 0;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (max-width: 991px) {
            .announcements .panel .panel-heading a span.plus-minus {
              height: 55px; } }
          .announcements .panel .panel-heading a span.plus-minus i {
            width: 22px;
            height: 22px;
            position: relative; }
            .announcements .panel .panel-heading a span.plus-minus i:before, .announcements .panel .panel-heading a span.plus-minus i:after {
              content: "" !important;
              display: inline-block;
              position: absolute;
              background: #ddd; }
            .announcements .panel .panel-heading a span.plus-minus i:before {
              width: 100%;
              height: 1px;
              top: 50%;
              margin-top: -0.5px; }
            .announcements .panel .panel-heading a span.plus-minus i:after {
              width: 1px;
              height: 100%;
              top: 0;
              left: 50%;
              margin-left: -0.5px; }
  .announcements .panel .panel-body {
    padding: 44px 37px;
    font-family: "Conv_Poppins-Light";
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #2a2a2a;
    border-top: 1px solid #ddd; }
    @media (max-width: 991px) {
      .announcements .panel .panel-body {
        padding: 20px 15px; } }
  .announcements .panel.active .panel-heading span.plus-minus i:after {
    opacity: 0; }

.cookie-policy {
  background: rgba(109, 59, 133, 0.9);
  padding: 19px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: none; }
  .cookie-policy.active {
    display: block; }
  .cookie-policy .container {
    position: relative; }
  .cookie-policy p {
    font-family: "Conv_Poppins-Light";
    font-size: 1.3rem;
    line-height: 1.6rem;
    width: 80%;
    color: #fff; }
    @media (max-width: 991px) {
      .cookie-policy p {
        width: 100%;
        padding-right: 50px; } }
    .cookie-policy p a {
      color: #fff;
      text-decoration: underline !important; }
  .cookie-policy a.cookie-close {
    display: inline-block;
    padding: 6px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    right: 15px;
    top: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 1; }
    .cookie-policy a.cookie-close i {
      width: 17px;
      height: 17px;
      position: relative;
      transform: rotate(45deg); }
      .cookie-policy a.cookie-close i:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.5px;
        width: 100%;
        height: 1px;
        background-color: #8240a1; }
      .cookie-policy a.cookie-close i:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -0.5px;
        width: 1px;
        height: 100%;
        background-color: #8240a1; }
