.main-announcements{
    margin-top:161px;
    position: relative;
    @media (max-width:$lg-width){
        margin-top:69px;
    }
    @media (max-width:$md-width){
        margin-top: 0;
        padding-bottom: 30px;
    }
    &:before{
        content:"";
        position: absolute;
        right:0;
        top:-1px;
        width: 58%;
        height: 1px;
        background-color: #e9e9e9;
        @media (max-width:$md-width){
            display: none;
        }
    }
    .container{
        position: relative;
        &:before{
            content:"";
            position: absolute;
            right: -1px;
            width: 1px;
            height:100%;
            top: 0;
            background-color: #e9e9e9;
            @media (max-width:$md-width){
                display: none;
            }
        }
        &:after{
            content:"";
            position: absolute;
            right: -1px;
            width: 1px;
            height:161px;
            top: -161px;
            background-color: #e9e9e9;
            @media (max-width:$md-width){
                display: none;
            }
        }
    }
    .row{
        > div{
            padding:0;
            @media (max-width:$md-width){
                padding:0 15px;
            }
            &:first-child{
                @media (max-width:$md-width){
                    order:2;
                }
            }
        }
    }
    section{
        padding:60px 15px 0 0;
        @media (max-width:$md-width){
            padding:0;
        }
        h2{
            font-family: $poppinsbold;
            @include font-size(35,48);
            color:$darkpurple;
            letter-spacing: 2px;
            margin-bottom:30px;
            @media (max-width:$md-width){
                margin-bottom: 0;
            }
        }
        .swiper{
            margin-bottom: 66px;
            @media (max-width:$md-width){
                margin-bottom: 30px;
            }
            .swiper-wrapper{
                height: 410px;
            }
            .swiper-slide{
                border-bottom: 1px solid #efefef;
            }
        }
        .swiper-actions{
            display: flex;
            justify-content: flex-start;
            > div{
                &.swipe-cont{
                    width: 49px;
                    height: 49px;
                    border:1px solid $darkpurple;
                    position: initial;
                    margin:0;
                    background:none;
                    margin-right: 5px;
                    @include transition;
                    &:before{
                        color:$darkpurple;
                    }
                    &:after{
                        content:none;
                    }
                    &.swiper-button-prev{
                        &:before{
                            content:"\e901";
                            font-family: 'icomoon';
                            font-size: 25px;
                            transform: rotate(270deg);
                        }
                    }
                    &.swiper-button-next{
                        &:before{
                            content:"\e901";
                            font-family: 'icomoon';
                            font-size: 25px;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .slide-announcements{
            padding:22px 0;
            span{
                font-family: $poppinsregular;
                @include font-size(15,20);
                color:$darkedpurple;
                margin-bottom: 15px;
                display: block;
                i{
                    font-size: 27px;
                    margin-right: 6px;
                    &:before{
                        color:$darkpurple;
                    }
                }
            }
            p{
                font-family: $poppinsregular;
                @include font-size(15,20);
                color:$darkedpurple;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
        }
    }
    figure{
        @media (max-width:$md-width){
            margin-bottom: 40px;
        }
        img{
            max-width: 100%;
            width: 100%;
        }
    }
}